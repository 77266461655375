import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { Card, Icon, ICON_REALID_BEAR } from "@ca-dmv/core";

export const IDENTITY_DOCUMENT = "document--identity";
export const NAME_CHANGE_DOCUMENT = "document--name-change";
export const NCH_NAME_CHANGE_DOCUMENT = "document--nch-name-change";
export const CARD_PROOF_OF_IDENTITY = "card-proof-of-identity";
export const CARD_RID_APPLICATION_NAME = "card-rid-application-name";

export function NameConfirmationCard({
  cardType,
  documentType,
  legalNameHeading,
  name,
  document,
  selectedIdentityDocument,
  containerClass,
  children,
}) {
  const backgroundColor = classNames({
    "bg--blue-light-4": cardType === CARD_RID_APPLICATION_NAME,
  });

  const borderColor = classNames({
    "border--gray": cardType === CARD_PROOF_OF_IDENTITY,
    "": cardType === CARD_RID_APPLICATION_NAME,
  });

  const containerClassName = classNames(
    "w--100 bp-md:max-width--600",
    containerClass
  );

  const cardContentClassName = classNames("flex", {
    "flex--col": cardType === CARD_PROOF_OF_IDENTITY,
    "flex--align-center flex--justify-between flex--nowrap":
      cardType === CARD_RID_APPLICATION_NAME,
  });

  const headingClassName = classNames("text--blue-dark-2 text--md text--700", {
    "mb-0 mr-8": cardType === CARD_PROOF_OF_IDENTITY,
    "mb-10": cardType === CARD_RID_APPLICATION_NAME,
  });

  const nameClassName = classNames("text--blue-dark-2 mb-0 word-break--word", {
    "text--sm": cardType === CARD_PROOF_OF_IDENTITY,
    "text--xmd": cardType === CARD_RID_APPLICATION_NAME,
  });

  const { t } = useTranslation();

  const documentHeading =
    documentType === IDENTITY_DOCUMENT
      ? t("screens-confirm-name-identityDocument", "Identity Document")
      : documentType === NCH_NAME_CHANGE_DOCUMENT
      ? t("screens-confirm-name-nch-nameChangeDocument", "Name Change Document")
      : t("screens-confirm-name-certifyingDocument", "Certifying Document");

  const heading =
    cardType === CARD_RID_APPLICATION_NAME
      ? t("component-rid-application-name-heading", "REAL ID Application Name:")
      : `${documentHeading}:`;

  return (
    <Card
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      containerClass={containerClassName}
      hasBorder={cardType === CARD_PROOF_OF_IDENTITY}
    >
      <div className={cardContentClassName}>
        {cardType === CARD_RID_APPLICATION_NAME && (
          <>
            <div>
              <p className={headingClassName}>{heading}</p>
              <p className={nameClassName}>{name}</p>
            </div>
            <span className="mr-8">
              <Icon
                icon={ICON_REALID_BEAR}
                className="mr-8"
                alt="Real Id Bear"
              />
            </span>
          </>
        )}
        {cardType === CARD_PROOF_OF_IDENTITY && (
          <>
            {(documentType === IDENTITY_DOCUMENT || documentType === NCH_NAME_CHANGE_DOCUMENT) && (
              <>
                <div className="flex flex--col mb-10">
                  <p className={headingClassName}>{heading}</p>
                  <p className={nameClassName}>{document}</p>
                </div>
                <div className="flex flex--col">
                  <p className="text--sm text--700 text--blue-dark-2 mb-0 mr-8">
                    <Trans
                      i18nKey="component-identity-application-name-on-document"
                      defaults="Name on {{identityDocument}}:"
                      values={{ identityDocument: document }}
                    />
                  </p>
                  <p className={nameClassName}>{name}</p>
                </div>
                {selectedIdentityDocument && (
                  <div className="flex flex--col mt-10">
                    <p className="text--blue-dark-2 text--700 text--sm mb-0 mr-8">
                      {t(
                        "screens-confirm-name-certifyingDocument",
                        "Name Change Certification Document"
                      )}
                    </p>
                    <p className="text--blue-dark-2 mb-0 text--sm mb-0">
                      {selectedIdentityDocument}
                    </p>
                  </div>
                )}
              </>
            )}
            {documentType === NAME_CHANGE_DOCUMENT && (
              <>
                <div className="flex flex--col mb-10">
                  <p className="text--blue-dark-2 text--700 text--md mb-0 mr-8">
                    {legalNameHeading}
                  </p>
                  <p className="text--blue-dark-2 mb-0 text--sm mb-0">{name}</p>
                </div>
                <div className="flex flex--col">
                  <p className="text--blue-dark-2 text--700 text--sm mb-0 mr-8">
                    {heading}
                  </p>
                  <p className="text--blue-dark-2 mb-0 text--sm mb-0">
                    {document}
                  </p>
                </div>
              </>
            )}
          </>
        )}
        {children}
      </div>
    </Card>
  );
}
