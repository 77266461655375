import { isAbbyyFlow, isBirthCertificate, isPassPort, isPassportCard } from '@ca-dmv-radv/screens/src/dashboard/helpers/constants';
import { useTranslation } from '@ca-dmv-radv/translation';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { uploadFile } from '../api';
import { useApplication } from '../application-context-provider';
import { useThrowFetchError } from '../error-context-provider';

const FileUploadContext = React.createContext();

const getBirthCertificateFormDataToSubmit = (
  originalBirthCertificateFormData
) => {
  const birthCertificateFormData = { ...originalBirthCertificateFormData };
  if (birthCertificateFormData.State_File_Nbr_Chk === "true") {
    birthCertificateFormData.State_File_Number = "";
  }

  if (birthCertificateFormData.Registrar_Nbr_Chk === "true") {
    birthCertificateFormData.Registrar_Number = "";
  }

  return birthCertificateFormData;
};

const defaultBirthCertificateFormData = {
  State_Of_Birth: "",
  County_Of_Birth: "",
  Mother_Maiden_Name: "",
  State_File_Number: "",
  Registrar_Number: "",
  Gender: "",
  uploadDocument: "",
  State_File_Nbr_Chk: "false",
  Registrar_Nbr_Chk: "false",
};

export const defaultPassportFormData = {
  Passport_Number: "",
  Confirm_Passport_Number: "",
  Passport_Expiration_Date: "",
};

export const defaultExpirationDateFormData = {
  Expiration_Date: ""
};

export function FileUploadContextProvider({ children }) {
  const [files, setFiles] = useState({});
  const [addingAdditionalPage, setAddingAdditionalPage] = useState(false);
  const [birthCertificateFormData, setBirthCertificateFormData] = useState(
    defaultBirthCertificateFormData
  );
  const [passportFormData, setPassportFormData] = useState(
    defaultPassportFormData
  );
  const [expirationDateFormData, setExpirationDateFormData] = useState(
    defaultExpirationDateFormData
  );
  const [showInputError, setShowInputError] = useState(false);
  const throwFetchError = useThrowFetchError();
  const { t } = useTranslation();
  const { maxFileSize, applicationType } = useApplication();

  /**
   * Sends file uploads to the API.
   */
  const postUploads = useCallback(
    async (documentUpload) => {
      const filesToUpload = files[documentUpload.id];

      if (!filesToUpload) {
        return true;
      }

      let success;

      let formData = {};

      if (isBirthCertificate(documentUpload)) {
        formData = getBirthCertificateFormDataToSubmit(
          birthCertificateFormData
        );
      } else if (
        isPassPort(documentUpload) ||
        isPassportCard(documentUpload)
      ) {
        formData = passportFormData;
      } else if (documentUpload.requiredExpirationDate) {
        formData = expirationDateFormData;
      }

      let mwcSourceId = 1;
      if (isMobile) {
        if (isAbbyyFlow(documentUpload.ATD)) {
          mwcSourceId = 1;
        } else {
          mwcSourceId = 2;
        }
      }

      try {
        ({ success } = await uploadFile(
          documentUpload,
          filesToUpload.map(({ original }) => original),
          formData,
          maxFileSize,
          mwcSourceId,
          applicationType
        ));

        console.log("Upload API called");
        console.log(`Upload success: ${success}`);

        if (!success) {
          throw new Error();
        }
      } catch (error) {
        console.log("Error with Upload");
        console.log(JSON.stringify(error));
        throwFetchError({
          error,
          message: t(
            "app-error-fetchingData-postUploads",
            "There was an error uploading your documents. Try again."
          ),
        });
      }
      //make a call from here if success for authenticateDocument --- one approach to try
      return success;
    },
    [
      files,
      birthCertificateFormData,
      passportFormData,
      expirationDateFormData,
      maxFileSize,
      throwFetchError,
      t,
    ]
  );

  return (
    <FileUploadContext.Provider
      value={useMemo(
        () => ({
          addingAdditionalPage,
          birthCertificateFormData,
          files,
          passportFormData,
          showInputError,
          expirationDateFormData,
          postUploads,
          setAddingAdditionalPage,
          setBirthCertificateFormData,
          setFiles,
          setShowInputError,
          setPassportFormData,
          setExpirationDateFormData,
          resetFormData: () => {
            setBirthCertificateFormData(defaultBirthCertificateFormData);
            setPassportFormData(defaultPassportFormData);
            setExpirationDateFormData(defaultExpirationDateFormData);
          },
        }),
        [
          addingAdditionalPage,
          birthCertificateFormData,
          files,
          passportFormData,
          expirationDateFormData,
          postUploads,
          showInputError,
        ]
      )}
    >
      {children}
    </FileUploadContext.Provider>
  );
}

export function useFileUpload() {
  return useContext(FileUploadContext);
}
