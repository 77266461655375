export default function sortDocuments(documents) {
  // Sort documents by document_tiles_order.
  const sortedDocuments = [...documents];
  sortedDocuments.sort((a, b) => {
      const aOrder = a.document_tiles_order || 0;
      const bOrder = b.document_tiles_order || 0;
      return aOrder - bOrder;
  });

  return sortedDocuments;
}