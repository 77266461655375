import { useMounted } from "@ca-dmv-radv/utilities";
import { useCallback } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useApplication } from "../application-context-provider";
import { getMDLNameChanges, getNameChanges } from "../api";
import { useThrowFetchError } from "../error-context-provider";
import { parseReceivedNameChanges } from "./parseReceivedNameChanges";
import { useDocumentUploads } from '../document-uploads-context-provider';
import useNameChanges from "./useNameChanges";

export default function useFetchNameChanges({ setNameChanges, category }) {
  const { application, applicationType } = useApplication();
  const { isMDLDocument } = useDocumentUploads();
  const mounted = useMounted();
  const throwFetchError = useThrowFetchError();
  const { t } = useTranslation;

  return useCallback(async () => {
    let success;
    let data;

    try {
      const nameChangeService = !isMDLDocument && getNameChanges;
      ({ data, success } = await nameChangeService(applicationType));
      if (mounted.current) {
        setNameChanges(parseReceivedNameChanges(data, application, category));
      }
    } catch (error) {
      throwFetchError({
        error,
        message: t(
          "app-error-fetchingData-nameChange",
          "There was an error synchronizing your name change data."
        ),
      });
    }

    return success;
  }, [application, setNameChanges, throwFetchError, t]);
}
