import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { DASHBOARD_STATUS_DONE } from "./helpers/useDashboardStatus";
import AcceptedContent from "./AcceptedContent";
import AppointmentLink from "./AppointmentLink";
import { useApplication } from "@ca-dmv-radv/data";
import { isApplicationNch } from "../constants";

export default function HeaderText({ dashboardStatus }) {
  const { t } = useTranslation();
  const { applicationType } = useApplication();
  const isNch = isApplicationNch(applicationType);

  if (dashboardStatus === DASHBOARD_STATUS_DONE) {
    return (
      <>
        <AcceptedContent />
        <AppointmentLink />
      </>
    );
  }

  return (
    <>
      <p className="text--blue-dark-2 max-width--700 mb-24">
        {isNch
          ? t(
              "nch-screens-Dashboard-pageDirections1",
              "Securely submit your documents online."
            )
          : t(
              "screens-Dashboard-pageDirections1",
              "Securely submit your documents online and save time at the DMV Office."
            )}
      </p>
      <p className="text--blue-dark-2 max-width--700 mb-40 bp-md:mb-48">
        {isNch
          ? t(
              "nch-screens-Dashboard-pageDirections2",
              "Select documents from the dropdown menus below, then click Continue to upload or take photos of them."
            )
          : t(
              "screens-Dashboard-pageDirections2",
              "Select documents from the dropdown menus below, then click Continue to upload or take photos of them. During your visit to DMV, you must present all the documents you submit here."
            )}
      </p>
    </>
  );
}
