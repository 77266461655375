import React from "react";
import { Modal } from "@ca-dmv/modal";
import { useTranslation } from "@ca-dmv-radv/translation";
import { ICON_TAKE_PHOTO, BUTTON_STYLE_LINK__ICON, Button, BUTTON_STYLE_SECONDARY, BUTTON_ICON_LEFT, ICON_CHECK, Icon, Note } from "@ca-dmv/core";
export default function RequiredPhotos({
  documentName,
  onClose,
  onCapture,
  onConfirm,
  requiredPages,
  document
}) {
  const { t } = useTranslation();

  return (
    <Modal
      onClose={onClose}
      modalTitle={t(
        "screens-Dashboard-Modal-RequiredPhotos-modalTitle",
        "Required Photos"
      )}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
    >
      <p className="text--xmd text--lh-sm text--blue-dark-2 mb-40">
        {t(
          "screens-Dashboard-Modal-RequirePhotos-subtitle",
          "Please provide the following photo(s) of your {{documentName}}",
          {
            documentName,
          }
        )}
      </p>
      <ul className="list--clean p-0 m-0 mb-24">
        {requiredPages?.map((page) => (
          <li
            key={page}
            className="bg--blue-light-4 text--blue-dark-2 text--md text--700 p-24 mt-16 flex flex--align-center flex--justify-between"
          >
            <>
            {t("screens-Dashboard-Modal-RequirePhotos-pagesList", {
              defaultValue: "1 Photo of the {{page}}",
              page,
            })}
            </>
          </li>
        ))}
      </ul>

      {requiredPages?.length == 2 && (
        <Note
          label={t(
            "screens-Dashboard-Modal-RequirePhotos-noteLabel",
            "Important"
          )}
        >
          <p>
            {t(
              "screens-Dashboard-Modal-RequirePhotos-note",
              "To upload the second REQUIRED photo, click 'Add page.' Proceed to 'Done' when complete."
            )}
          </p>
        </Note>
      )}

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={t(
            "screens-Dashboard-Modal-TakePhotoWaiting-buttonCancelLabel",
            "Cancel"
          )}
          btnStyle={BUTTON_STYLE_SECONDARY}
          onClick={onClose}
        />
        <Button
          label={t(
            "screens-Dashboard-Modal-TakePhotoWaiting-buttonOkLabel",
            "Continue"
          )}
          onClick={onCapture}
        />
      </div>
    </Modal>
  );
}
