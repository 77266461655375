import React, { useEffect, useMemo, useState } from "react";
import {
  RadioGroup,
  RadioItem,
  Button,
  ButtonWrapper,
  useFocusedKey,
  Alert,
  ALERT_ERROR
} from "@ca-dmv/core";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import {
  RadvPageWrapper,
  NameConfirmationCard,
  CARD_RID_APPLICATION_NAME,
  ButtonSaveAndExit,
  ButtonBack,
  NameFields,
} from "@ca-dmv-radv/components";
import { useApplication, useResidencyDocuments } from "@ca-dmv-radv/data";
import useConfirmResidencyNameNavigation from "./useConfirmResidencyNameNavigation";
import { ICON_WARNING_RED } from "@ca-dmv/core/dist/js/icon";
import sortDocuments from "@ca-dmv-radv/utilities/src/sortDocuments";

function ConfirmResidencyName({ docNumber, title }) {
  const {
    selectedResidencyDocuments,
    residencyDocDetails,
    setResidencyDocDetails,
    relationshipDocuments,
    selectedRelationshipDocuments,
    setSelectedRelationshipDocument,
    showConnectingDocOptions,
  } = useResidencyDocuments();
  const { applicationName } = useApplication();

  const selectedResidencyDocument = selectedResidencyDocuments?.[docNumber];
  const selectedRelationshipDocument =
    selectedRelationshipDocuments?.[docNumber];
  const details = residencyDocDetails[docNumber];
  const [fullName, setFullName] = useState();

  const { t } = useTranslation();
  const { focusedElementKey } = useFocusedKey();

  const [showErrors, setShowErrors] = useState(false);

  const { nameOption, documentName } = details;
  const [selectedNameOption, setSelectedNameOption] = useState(null);
  const { firstName, lastName, middleName, suffix } = documentName;
  const sortedRelationshipDocuments = sortDocuments(relationshipDocuments);
  const errors = useMemo(() => {
    const newErrors = {};

    if (details.nameOption === null) {
      newErrors.nameMatches = t(
        "errorMessages.nameMatchesMissing",
        "Please select if this is the name that matches your document."
      );
    }

    if ((!lastName || lastName.trim() === "") && nameOption === 2) {
      newErrors.lastName = t(
        "errorMessages.differentLastNameMissing",
        "Please enter the last name displayed."
      );
    }

    if (!selectedRelationshipDocument && nameOption === 2) {
      newErrors.relationshipDocument = (
        <Trans
          i18nKey="errorMessages.selectedRelationshipDocumentMissing"
          defaults="Please select a document that certifies your relationship to {{name}}."
          values={{
            name: fullName,
          }}
        />
      );
    }

    return newErrors;
  }, [
    details.nameOption,
    details.documentName.firstName,
    details.documentName.lastName,
    t,
    selectedRelationshipDocument,
  ]);

  const { setRequestingNavigation, navigating } =
    useConfirmResidencyNameNavigation({
      errors,
      nameOption: details.nameOption,
      setShowErrors,
      docNumber,
      selectedRelationshipDocument,
    });

  const capitalize = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    if (firstName && lastName) {
      setFullName(
        `${firstName ? capitalize(firstName) : ""} ${
          middleName ? capitalize(middleName) : ""
        } ${lastName ? capitalize(lastName) : ""} ${
          suffix ? capitalize(suffix) : ""
        }`
      );
    } else {
      setFullName(
        t("screens-ProofOfCaliforniaResidency-missingName", "the name above")
      );
    }
  }, [documentName]);

  return (
    <RadvPageWrapper
      pageHeading={t(
        "screens-ProofOfCaliforniaResicency-legend",
        "Proof of California Residency"
      )}
      formProps={{ isForm: true, showErrors, errors }}
      pageTitle={title}
    >
    {!showConnectingDocOptions && 
      <div>
        <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
          {t(
            "screens-returnToDashboard-backButton-warning",
            "Please avoid using the browser navigation buttons to make changes to your application."
          )}
        </p>
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <Button
          label={t("screens-returnToDashboard-button-text", "Return to Dashboard")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => setRequestingNavigation(true)}
          isLoading={navigating}
        />
      </ButtonWrapper>
      </div>
    }
      {showConnectingDocOptions && <div>
        <div className="flex flex--col bp-md:flex--row mb-40 bp-md:mb-48 w--100 max-width--600 mr-0">
        <NameConfirmationCard
          cardType={CARD_RID_APPLICATION_NAME}
          name={applicationName}
        />
      </div>

      <RadioGroup
        containerClass="mb-0 pb-0 max-width--600"
        legend={
          <Trans
            i18nKey="screens-ProofOfCaliforniaResidency-doesDocumentDisplayName"
            defaults="Is <span>{{fullName}}</span> the name on your <span>{{document}}</span>?"
            values={{
              fullName: applicationName,
              document:
                selectedResidencyDocument?.name ||
                t(
                  "screens-confirm-residency-name-residencyDocumentDescription",
                  "Document used to establish your residency"
                ),
            }}
            components={{ span: <span className="text--blue" /> }}
          />
        }
        error={errors && showErrors && errors.nameMatches}
      >
        <RadioItem
          label={t("shared.yesLabel", "Yes")}
          radioStyle="bordered"
          containerClass="w--100 bp-md:max-width--600 mb-24 pb-0"
          name="nameMatches"
          checked={selectedNameOption === 0}
          onChange={() => {setSelectedNameOption(0)}}
          hideAsterisk
          hasFocus={focusedElementKey && focusedElementKey === "nameMatches"}
        />
        <RadioItem
          label={t(
            "screens-ProofOfCaliforniaResidency-documentDisplaysDifferentName",
            "No, this document displays a different name."
          )}
          note={t(
            "screens-ProofOfCaliforniaResidency-documentDisplaysDifferentNameProvideDocument",
            "I will provide a document that connects me to this person (example: spouse or family member)."
          )}
          noteClass="text--xsm"
          hideNoteLabel
          radioStyle="bordered"
          containerClass="w--100 bp-md:max-width--600 mb-24 pb-0"
          name="name-matches"
          checked={selectedNameOption === 2}
          onChange={() => {setSelectedNameOption(2)}}
          hideAsterisk
          hasFocus={
            focusedElementKey && focusedElementKey === "nameValidation"
          }
        />
        <RadioItem
          label={t(
            "screens-ProofOfCaliforniaResidency-documentDisplaysDifferentName",
            "No, this document displays a different name."
          )}
          note={t(
            "screens-ProofOfCaliforniaResidency-documentDisplaysDifferentNameDifferentDocument",
            "I would like to choose a different document that displays my name."
          )}
          noteClass="text--xsm"
          hideNoteLabel
          radioStyle="bordered"
          containerClass="w--100 bp-md:max-width--600 mb-0 pb-0"
          name="name-matches"
          checked={selectedNameOption === 1}
          onChange={() => {setSelectedNameOption(1)}}
          hideAsterisk
        />
      </RadioGroup>
      {selectedNameOption === 2 && (
        <>
          <NameFields
            namePrompt={
              <Trans
                i18nKey="screens-ProofOfCaliforniaResidency-nameOnDocumentPrompt"
                defaults="What name is displayed on your <span>{{document}}</span>?"
                values={{
                  document: selectedResidencyDocument.name,
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            firstName={details.documentName.firstName || ""}
            middleName={details.documentName.middleName || ""}
            lastName={details.documentName.lastName || ""}
            suffix={details.documentName.suffix || ""}
            onChangeFirstName={(nextFirstName) => {
              setResidencyDocDetails(docNumber, {
                documentName: {
                  ...details.documentName,
                  firstName: nextFirstName,
                },
              });
            }}
            onChangeMiddleName={(nextMiddleName) => {
              setResidencyDocDetails(docNumber, {
                documentName: {
                  ...details.documentName,
                  middleName: nextMiddleName,
                },
              });
            }}
            onChangeLastName={(nextLastName) => {
              setResidencyDocDetails(docNumber, {
                documentName: {
                  ...details.documentName,
                  lastName: nextLastName,
                },
              });
            }}
            onChangeSuffix={(nextSuffix) => {
              setResidencyDocDetails(docNumber, {
                documentName: {
                  ...details.documentName,
                  suffix: nextSuffix,
                },
              });
            }}
            errors={errors}
            showErrors={showErrors}
            focusedElementKey={errors.nameValidation}
          />
          <RadioGroup
            containerClass="mb-0 pb-0"
            legend={
              <Trans
                i18nKey="screens-ProofOfCaliforniaResidency-documentDisplaysDifferentNamePrompt"
                defaults="Which document connects you to <span>{{name}}</span>?"
                values={{
                  name: fullName,
                }}
                components={{ span: <span className="text--blue" /> }}
              />
            }
            explanation={t(
              "screens-ProofOfCaliforniaResidency-documentDisplaysDifferentNamePromptExplanation",
              "If the California Residency documents you have selected are in another individual’s name, you must provide a document from the list below that connects you to that individual."
            )}
            error={errors && showErrors && errors.relationshipDocument}
          >
            {sortedRelationshipDocuments.map((doc, index) => (
              <RadioItem
                key={doc.id}
                index={index}
                label={doc.name}
                note={doc.note}
                noteClass="text--xsm"
                containerClass={
                  index < sortedRelationshipDocuments.length - 1 ? "mb-24" : "mb-0"
                }
                radioStyle="bordered"
                radioClass="w--100 bp-md:max-width--600 mb-0 pb-0"
                name={doc.name}
                checked={doc.id === selectedRelationshipDocument?.id}
                onChange={() => {
                  setSelectedRelationshipDocument(docNumber, doc);
                }}
                hideAsterisk
                hasFocus={
                  focusedElementKey &&
                  focusedElementKey === "relationshipDocument" &&
                  index === 0
                }
              />
            ))}
          </RadioGroup>
        </>
      )}
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <ButtonBack buttonClass="bp-md:mr-24" />
        <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        <Button
          label={t("shared.nextLabel", "Next")}
          buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0"
          onClick={() => {
            setResidencyDocDetails(docNumber, { complete: true });
            setResidencyDocDetails(docNumber, { nameOption: selectedNameOption });
            setRequestingNavigation(true);
          }}
          isLoading={navigating}
        />
      </ButtonWrapper>
      </div>}
    </RadvPageWrapper>
  );
}

export default ConfirmResidencyName;
