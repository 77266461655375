import { useApplication, useFileUpload } from "@ca-dmv-radv/data";
import { useMemo } from "react";
import { isAbbyyFlow } from "../helpers/constants";
import { isMobile } from "react-device-detect";

const CATEGORY = {
  1: "PASSPORT",
  2: "PASSPORT_CARD",
  61: "PASSPORT",
  62: "PASSPORT_CARD",
  82: "PASSPORT",
  83: "PASSPORT_CARD",
  120: "PASSPORT",
  121: "PASSPORT_CARD",
};
const MIN_LENGTH = {
  PASSPORT: 6,
  PASSPORT_CARD: 9,
};
const MAX_LENGTH = {
  PASSPORT: 9,
  PASSPORT_CARD: 9,
};

export default function useVerificationFieldsValidation() {
  const { birthCertificateFormData, passportFormData, expirationDateFormData } = useFileUpload();

  /**
   * Checks Birth Certificate form fields are not empty
   * Used to enable/disable the modal submit button
   */
  const validBCForm =
    !birthCertificateFormData.State_Of_Birth ||
    (!birthCertificateFormData.State_File_Number &&
      birthCertificateFormData.State_File_Nbr_Chk === "false") ||
    (!birthCertificateFormData.Registrar_Number &&
      birthCertificateFormData.Registrar_Nbr_Chk === "false") ||
    (birthCertificateFormData.State_Of_Birth === "CA" &&
      !birthCertificateFormData.County_Of_Birth);  

  const calculateExpirationDateDifference = (expDate) => {
    const today = new Date();
    const difference = expDate.getTime() - today.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  }

  const validatePassportExpirationDate = (date) => {
    const expDate = new Date(date || passportFormData.Passport_Expiration_Date);
    const diffDays = calculateExpirationDateDifference(expDate);

    // expDate is more than 30days from today, but less than 3652.5 days from today.
    return diffDays >= 30 && diffDays < 3652.5;
  };

  const validateGeneralExpirationDate = (date) => {
    const expDate = new Date(date || expirationDateFormData.Expiration_Date);
    const diffDays = calculateExpirationDateDifference(expDate);

    // expDate is more than 2 days from today, but less than 3652.5 days (10 years) from today.
    return diffDays >= 2 && diffDays < 3652.5
  }
  // is the value between the min/max
  const isValidInputLength = (value, categoryId) => {
    const minLength = MIN_LENGTH[CATEGORY[categoryId]];
    const maxLength = MAX_LENGTH[CATEGORY[categoryId]];
    return value.length >= minLength && value.length <= maxLength;
  };

  const emptyInputs =
    passportFormData.Passport_Number === "" ||
    passportFormData.Passport_Expiration_Date.length !== 10;

  const inputMismatch = (atdStatus) => {
    if (isAbbyyFlow(atdStatus) || !isMobile) {
      return passportFormData.Passport_Number !== passportFormData.Confirm_Passport_Number
    } else {
      return false;
    }
  }
  /**
   * Checks Passport form fields are not empty
   * Checks Passport numbers match and expiration is 30+ days from today
   * Used to enable/disable the modal submit button
   */
  const validPassportForm = (atdStatus) => {
    return !emptyInputs && validatePassportExpirationDate() && !inputMismatch(atdStatus);
  }

  const isValidPassportForm = (categoryId, atdStatus) => {
    return (
      validPassportForm(atdStatus) &&
      isValidInputLength(passportFormData.Passport_Number, categoryId)
    );
  };

  const isValidExpirationDateForm = validateGeneralExpirationDate() &&
    expirationDateFormData.Expiration_Date.length === 10;

  return useMemo(
    () => ({
      validBCForm,
      isValidPassportForm,
      validatePassportExpirationDate,
      validateGeneralExpirationDate,
      isValidInputLength,
      isValidExpirationDateForm,
    }),
    [
      validBCForm,
      isValidPassportForm,
      validatePassportExpirationDate,
      validateGeneralExpirationDate,
      isValidInputLength,
      isValidExpirationDateForm,
    ]
  );
}
