import { getDocumentTitle } from "@ca-dmv-radv/data";
import {
  Appointments,
  ConfirmResidencyName,
  Dashboard,
  ErrorScreen,
  FieldOffices,
  FinalConfirmation,
  Identity,
  IdentityNameChanges,
  MDLHome,
  DynamicDashboard,
  DynamicAppsDashboard,
  DynamicFinalConfirmation,
  DynamicErrorScreen,
  MDLSearch,
  DynamicExpiredScreen,
  MDLNoVerification,
  MobileSessionDv,
  DynamicMobileSession,
  PageNotFound,
  PresenceVerificationLanding,
  SaveAndExit,
  Timeout,
  UnderMaintenance,
  UnderReview,
  MDLLandingPage,
  GenericNameConfirmation,
  GenericNameChanges,
} from "@ca-dmv-radv/screens";

import React from "react";

/**
 * Routes for the application.
 */
const routes = [
  {
    path: "/",
    title: getDocumentTitle("Dashboard"),
    Component: Dashboard,
    eventName: "RADVdocumentVerification",
  },
  {
    path: "/identity",
    title: getDocumentTitle("Identity Document"),
    Component: Identity,
    eventName: "RADVIdentityProofStart",
  },
  {
    path: "/identity/name-changes",
    title: getDocumentTitle("Name Change Documents"),
    Component: IdentityNameChanges,
    eventName: "RADVIdentityNameChanges",
  },
  {
    path: "/residency/doc1",
    title: getDocumentTitle("Confirm Residency Name"),
    Component: () => <ConfirmResidencyName docNumber={1} />,
    eventName: "RADVStateResidencyDoc1Start",
  },
  {
    path: "/residency/doc2",
    title: getDocumentTitle("Confirm Residency Name"),
    Component: () => <ConfirmResidencyName docNumber={2} />,
    eventName: "RADVStateResidencyDoc2Start",
  },
  {
    path: "/appointments",
    title: getDocumentTitle("Appointments"),
    Component: Appointments,
    eventName: "RADVAppointments",
  },
  {
    path: "/appointment-location",
    title: getDocumentTitle("Appointment Location"),
    Component: FieldOffices,
    eventName: "RADVAppointmentLocation",
  },
  {
    path: "/error",
    title: "Error",
    Component: ErrorScreen,
    eventName: "RADVError",
  },
  {
    path: "/under-review",
    title: "Under Review",
    Component: UnderReview,
    eventName: "RADVUnderReview",
  },
  {
    path: "/under-maintenance",
    title: "Under Maintenance",
    Component: UnderMaintenance,
    eventName: "RADVUnderMaintenance",
  },
  {
    path: "/final-confirmation",
    title: getDocumentTitle("Final Confirmation"),
    Component: FinalConfirmation,
    eventName: "RADVFinalConfirmation",
  },
  {
    path: "/saved",
    title: getDocumentTitle("Saved"),
    Component: () => <SaveAndExit />,
    eventName: "RADVdocumentVerificationComplete",
  },
  {
    path: "/log-out",
    title: "Log Out",
    Component: () => <SaveAndExit isLogout />,
  },
  {
    path: "/auto-saved",
    title: getDocumentTitle("Saved"),
    Component: () => <UnderReview autoSubmit />,
    eventName: "RADVdocumentVerificationComplete",
  },
  {
    path: "/presence-verification",
    title: "Presence Verification Landing Page",
    Component: PresenceVerificationLanding,
    eventName: "RADVpresenceVerificationLandingPage",
  },
  {
    path: "/mdl",
    title: "mDL",
    Component: MDLHome,
  },
  {
    path: "/*",
    title: getDocumentTitle("Page Not Found"),
    Component: PageNotFound,
    eventName: "RADVPageNotFound",
  },
];

export const unauthenticatedRoutes = [
  {
    path: "/ms/:token",
    title: getDocumentTitle("Mobile Session"),
    Component: MobileSessionDv,
  },
  {
    path: "/ms/mdl/:token",
    title: getDocumentTitle("Mobile Session"),
    Component: () => <DynamicMobileSession applicationType="mdl" />,
  },
  {
    path: "/ms/odp/:token",
    title: getDocumentTitle("Mobile Session"),
    Component: () => <DynamicMobileSession applicationType="odp" />,
  },
  {
    path: "/ms/vdl/:token",
    title: getDocumentTitle("Mobile Session"),
    Component: () => <DynamicMobileSession applicationType="vdl" />,
  },
  {
    path: "/ms/nch/:token",
    title: getDocumentTitle("Mobile Session"),
    Component: () => <DynamicMobileSession applicationType="nch" />,
  },
  {
    path: "/ms/start/:token",
    title: getDocumentTitle("Begin MDL Flow"),
    Component: MDLLandingPage,
    eventName: "MDLLanding",
  },
  {
    path: "/timeout",
    title: getDocumentTitle("Timeout"),
    Component: () => <Timeout applicationType="dv" />,
  },
  {
    path: "/*",
    title: getDocumentTitle("Page Not Found"),
    Component: PageNotFound,
  },
  {
    path: "/dv/expired",
    title: getDocumentTitle("Expired"),
    Component: MobileSessionDv,
  },
  {
    path: "/mdl/error",
    title: getDocumentTitle("Error"),
    Component: DynamicErrorScreen,
  },
  {
    path: "/mdl/search",
    title: getDocumentTitle("Search"),
    Component: () => <MDLSearch applicationType="mdl" />,
    eventName: "MDLSearch",
  },
  {
    path: "/mdl/expired",
    title: getDocumentTitle("Expired"),
    Component: () => <DynamicExpiredScreen applicationType="mdl" />,
    eventName: "MDLExpired",
  },
  {
    path: "/mdl/timeout",
    title: getDocumentTitle("Timeout"),
    Component: () => <Timeout applicationType="mdl" />,
    eventName: "MDLTimeout",
  },
  {
    path: "/mdl/under-maintenance",
    title: "Under Maintenance",
    Component: UnderMaintenance,
    eventName: "MDLUnderMaintenance",
  },
  {
    path: "/odp/error",
    title: getDocumentTitle("Error"),
    Component: DynamicErrorScreen,
    eventName: "ODPError",
  },
  {
    path: "/odp/expired",
    title: getDocumentTitle("Expired"),
    Component: () => <DynamicExpiredScreen applicationType="odp" />,
    eventName: "ODPExpired",
  },
  {
    path: "/odp/timeout",
    title: getDocumentTitle("Timeout"),
    Component: () => <Timeout applicationType="odp" />,
    eventName: "ODPTimeout",
  },
  {
    path: "/odp/under-maintenance",
    title: getDocumentTitle("Under Maintenance"),
    Component: UnderMaintenance,
    eventName: "ODPUnderMaintenance",
  },
  {
    path: "/vdl/error",
    title: getDocumentTitle("Error"),
    Component: DynamicErrorScreen,
    eventName: "VDLError",
  },
  {
    path: "/vdl/expired",
    title: getDocumentTitle("Expired"),
    Component: () => <DynamicExpiredScreen applicationType="vdl" />,
    eventName: "VDLExpired",
  },
  {
    path: "/vdl/timeout",
    title: getDocumentTitle("Timeout"),
    Component: () => <Timeout applicationType="vdl" />,
    eventName: "VDLTimeout",
  },
  {
    path: "/vdl/under-maintenance",
    title: getDocumentTitle("Under Maintenance"),
    Component: UnderMaintenance,
    eventName: "VDLUnderMaintenance",
  },
  {
    path: "/vdl/search",
    title: getDocumentTitle("Search"),
    Component: () => <MDLSearch applicationType="vdl" />,
    eventName: "VDLSearch",
  },
  {
    path: "/nch/error",
    title: getDocumentTitle("Error"),
    Component: DynamicErrorScreen,
    eventName: "NCHError",
  },
  {
    path: "/nch/expired",
    title: getDocumentTitle("Expired"),
    Component: () => <DynamicExpiredScreen applicationType="nch" />,
    eventName: "NCHExpired",
  },
  {
    path: "/nch/timeout",
    title: getDocumentTitle("Timeout"),
    Component: () => <Timeout applicationType="nch" />,
    eventName: "NCHTimeout",
  },
  {
    path: "/nch/under-maintenance",
    title: getDocumentTitle("Timeout"),
    Component: UnderMaintenance,
    eventName: "NCHUnderMaintenance",
  },
];
export const mdlRoutes = [
  {
    path: "/document-upload",
    title: getDocumentTitle("Dashboard"),
    Component: () => <DynamicDashboard applicationType="mdl" />,
    eventName: "MobileDiversLicence",
  },
  {
    path: "/presence-verification",
    title: getDocumentTitle("Dashboard"),
    Component: () => <PresenceVerificationLanding program="mdl" />,
    eventName: "MobileDiversLicence",
  },
  {
    path: "/final-confirmation",
    title: getDocumentTitle("Final Confirmation"),
    Component: () => <DynamicFinalConfirmation applicationType="mdl" />,
    eventName: "MDLFinalConfirmation",
  },
  {
    path: "/no-verification",
    title: getDocumentTitle("No Verification Needed"),
    Component: MDLNoVerification,
    eventName: "MDLNoVerification",
  },
];
export const odpRoutes = [
  {
    path: "/presence-verification",
    title: getDocumentTitle("Dashboard"),
    Component: () => <PresenceVerificationLanding program="odp" />,
    eventName: "ODPPresenceVerification",
  },
  {
    path: "/final-confirmation",
    title: getDocumentTitle("Final Confirmation"),
    Component: () => <DynamicFinalConfirmation applicationType="odp" />,
    eventName: "MDLFinalConfirmation",
  },
];
export const vdlRoutes = [
  {
    path: "/presence-verification",
    title: getDocumentTitle("Presence Verification"),
    Component: () => <PresenceVerificationLanding program="vdl" />,
    eventName: "VDLPresenceVerification",
  },
  {
    path: "/document-upload",
    title: getDocumentTitle("Dashboard"),
    Component: () => <DynamicDashboard applicationType="vdl" />,
    eventName: "VDLDashboard",
  },
  {
    path: "/final-confirmation",
    title: getDocumentTitle("Final Confirmation"),
    Component: () => <DynamicFinalConfirmation applicationType="vdl" />,
    eventName: "VDLFinalConfirmation",
  },
];
export const nchRoutes = [
  {
    path: "/",
    title: getDocumentTitle("Dashboard"),
    Component: () => <DynamicAppsDashboard applicationType="nch" />,
    eventName: "NCHDashboard",
  },
  {
    path: "/presence-verification",
    title: getDocumentTitle("Presence Verification"),
    Component: () => <PresenceVerificationLanding program="nch" />,
    eventName: "NCHPresenceVerification",
  },
  {
    path: "/final-confirmation",
    title: getDocumentTitle("Final Confirmation"),
    Component: () => <FinalConfirmation applicationType="nch" />,
    eventName: "NCHFinalConfirmation",
  },
  {
    path: "/name-confirmation",
    title: getDocumentTitle("Name Change Document"),
    Component: GenericNameConfirmation,
    eventName: "NCHNameConfirmation",
  },
  {
    path: "/name-changes",
    title: getDocumentTitle("Name Change Documents"),
    Component: GenericNameChanges,
    eventName: "NCHNameChanges",
  },
  {
    path: "/appointment-location",
    title: getDocumentTitle("Appointment Location"),
    Component: FieldOffices,
    eventName: "NCHAppointmentLocation",
  },
  {
    path: "/appointments",
    title: getDocumentTitle("Appointments"),
    Component: Appointments,
    eventName: "NCHAppointments",
  },
  {
    path: "/saved",
    title: getDocumentTitle("Saved"),
    Component: () => <SaveAndExit />,
    eventName: "NCHdocumentVerificationComplete",
  },
  {
    path: "/log-out",
    title: "Log Out",
    Component: () => <SaveAndExit isLogout />,
    eventName: "NCHLogout",
  },
];
export default routes;
