import {
  useDocumentUploads,
  useGoogleAnalytics,
  useIdentityDocument,
  useNameChanges,
} from "@ca-dmv-radv/data";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import { useSortedDocuments } from "@ca-dmv-radv/utilities";
import {
  Alert,
  ALERT_ERROR,
  ALERT_WARNING,
  Button,
  ICON_SMALL,
  ICON_STOP,
  SelectMenu,
  Separator,
  STATUS_ERROR,
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE,
  STATUS_READY,
  STATUS_REVIEW,
  STATUS_SUBMITTING,
  STATUS_VERIFYING,
  StatusContainer,
} from "@ca-dmv/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Document from "../Document";
import getDocumentUploadStatusFlag from "../helpers/getDocumentUploadStatusFlag";
import NameChangeUploads from "./NameChangeUploads";
import { isPendingConfirmationStatus } from "../PresenceVerification/constants";

export default function ProofOfIdentity({
  errors,
  showErrors,
  identityCatDocData,
  nameChangeCatDocData,
  isMdl,
}) {
  const {
    selectedIdentityDocument,
    setSelectedIdentityDocument,
    identityDocuments,
    postIdentityDocumentStatus,
    identityStatus,
    updateShowIdentityNameOptions,
  } = useIdentityDocument();
  const {
    identityDocumentUploads,
    nameChangeDocumentUploads,
    setCurrentFocusedDoc,
    setHasStartedPoI,
    isMDLDocument,
    fetchDocumentUploads,
  } = useDocumentUploads();
  const { noDocsOption, hasNameChangeDocs, nameMatches, setSelectedNameMatchesOption } = useNameChanges();
  const { t } = useTranslation();
  const [hint, setHint] = useState();
  const navigate = useNavigate();
  const [navigating, setNavigating] = useState(false);
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { documentUploads } = useDocumentUploads();
  const [confirmFields, setConfirmFields] = useState(false);
  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showSelected, setshowSelected] = useState(false);

  const requestNavigation = async (isMDLDocument) => {
    if (navigating) {
      return;
    }

    setNavigating(true);
    setAnalyticsAction("Submit Identity Doc");
    const success = await postIdentityDocumentStatus();
    setCurrentFocusedDoc("proof-of-identity");
    setHasStartedPoI(true);
    if (success && !isMDLDocument) {
      setSelectedNameMatchesOption(null);
      navigate("/identity");
    } else {
      setshowSelected(true);
      fetchDocumentUploads();
      setNavigating(false);
    }
  };

  const sortedIdentityDocumentOptions = useSortedDocuments({
    documents: identityDocuments,
  });

  const determineShouldShowSelect = () => {
    const docDoesNotMatch = !selectedIdentityDocument?.id !== !identityDocumentUploads[0]?.id;
    const noNameMatches = nameMatches === null;
    const noSelectedDocsOption = nameMatches === false && noDocsOption === null && (!hasNameChangeDocs || !nameChangeDocumentUploads?.length);
    return docDoesNotMatch || noNameMatches || noSelectedDocsOption;
  };

  const shouldShowSelect = determineShouldShowSelect();

  const showSubheading = [
    "",
    STATUS_IN_PROGRESS,
    STATUS_VERIFYING,
    STATUS_ERROR,
    STATUS_INCOMPLETE,
  ].includes(identityStatus);

  const showVerificationMessage = [STATUS_VERIFYING, STATUS_REVIEW].includes(
    identityStatus
  );

  const subHeadingClass = classNames("max-width--600 text--blue-dark-2");

  const updateIdentityDocument = (val) => {
    setSelectedIdentityDocument(
      identityDocuments.find((doc) => doc.documentId === Number(val))
    );
  };

  useEffect(() => {
    const allOptions = [];
    for (let i = 0; i < sortedIdentityDocumentOptions.length; i += 1) {
      allOptions.push(sortedIdentityDocumentOptions[i]);
    }
    const selectedObject = allOptions.find(
      (option) => option.value === selectedIdentityDocument?.id
    );
    if (selectedObject) {
      setHint(selectedObject.hint);
    } else {
      setHint("");
    }
  }, [selectedIdentityDocument]);

  useEffect(() => {
    if (
      isMDLDocument &&
      documentUploads &&
      typeof documentUploads[8] === "undefined"
    ) {
      setshowSelected(false);
    }
  }, [documentUploads]);

  useEffect(() => {
    if (
      documentUploads &&
      typeof documentUploads[8] !== "undefined" &&
      isMDLDocument &&
      selectedIdentityDocument?.id
    ) {
      setshowSelected(true);
    }
  }, []);

  return (
    <StatusContainer
      containerClass="mb-40 bp-md:mb-48 max-width--900"
      heading={
        <h3 className="mb-24 text--primary">
          {t("screens-ProofOfIdentity-legend", "Proof of Identity")}
        </h3>
      }
      headingClass="mb-24"
      subHeading={
        <Trans
          i18nKey={
            isMDLDocument
              ? "screens-ProofOfIdentity-subLegend-mdl"
              : "screens-ProofOfIdentity-subLegend"
          }
          defaults="<p1>Proof of Identity documents must be original or certified copies.</p1><p2>Once your documents have been uploaded or photographed, you will not be able to select a different document.</p2>"
          components={{
            p1: <span className="show mb-16" />,
            p2: <span className="show mb-0" />,
          }}
        />
      }
      status={
        identityStatus !== STATUS_SUBMITTING ? identityStatus : STATUS_READY
      }
      showSubheading={showSubheading}
      subHeadingClass={subHeadingClass}
    >
      {showVerificationMessage && (
        <Alert
          alertStyle={ALERT_WARNING}
          ariaLive="off"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-verificationMessage",
              "You will receive an email when your documents have been verified. This can take up to 2 business days."
            )}
          </p>
        </Alert>
      )}
      {shouldShowSelect && !showSelected ? (
        <>
          <div className="js-doc-wrap flex flex--col bp-md:flex--row  flex--nowrap flex--justify-center flex--align-center bp-sm:flex--align-start bp-md:flex--align-start bp-md:flex--justify-start">
            <SelectMenu
              containerClass="bp-md:mb-5 w--100 bp-md:w--auto max-width--400"
              selectClass="w--100 bp-md:max-width--400"
              label={t(
                "screens-ProofOfIdentity-label",
                "Proof of Identity Document"
              )}
              hint={hint}
              selectPlaceholder={t(
                "screens-ProofOfIdentity-selectIdentityPlaceholder",
                "Select Proof of Identity Document"
              )}
              options={sortedIdentityDocumentOptions}
              selectedValue={selectedIdentityDocument?.documentId}
              onChange={updateIdentityDocument}
              error={errors && showErrors && errors.proofOfIdentity}
              // hasFocus={
              //   focusedElementKey && focusedElementKey === "proofOfIdentity"
              // }
              hideAsterisk={isMdl}
            />
            {selectedIdentityDocument?.id && (
              <div>
                <Button
                  isLoading={navigating}
                  buttonClass="mt-24 bp-md:ml-24"
                  label={t(
                    "screens-ProofOfIdentity-continueButtonLabel",
                    "Continue"
                  )}
                  onClick={() =>{
                    updateShowIdentityNameOptions(true);
                    requestNavigation(isMDLDocument);
                  }}
                />
              </div>
            )}
          </div>
          {!isMdl && (
            <Alert
              alertStyle={ALERT_WARNING}
              ariaLive="off"
              containerClass="mt-24 mb-0 bp-md:max-width--500 bp-lg:max-width--600"
              hasBorder={false}
            >
              <p>
                <Trans
                  i18nKey="screens-ProofOfIdentity-differentNameAlert"
                  defaults="If your identity document shows a different name than your application, you must submit a name change certification document."
                />
              </p>
              <p className="mb-0">
                <Trans
                  i18nKey="screens-ProofOfIdentity-nameChangeMultipleTimes"
                  defaults="If your name has changed multiple times, you will be asked to provide a name change certification document for each change."
                />
              </p>
            </Alert>
          )}
        </>
      ) : (
        <>
          <Document
            status={getDocumentUploadStatusFlag(
              identityDocumentUploads[0],
              documentUploads,
              isMDLDocument
            )}
            isCancelable
            onCancelDocument={() => {
              if (isMDLDocument) {
                setshowSelected(false);
              }
              setSelectedIdentityDocument(null);
            }}
            documentUpload={identityDocumentUploads[0]}
            showSelectDifferentDocument={shouldShowSelect}
            confirmFields={confirmFields}
            setConfirmFields={setConfirmFields}
            showIdentityForm={showIdentityForm}
            setShowIdentityForm={setShowIdentityForm}
            containerClass="js-doc-wrap"
          />
          {identityDocumentUploads.length > 1 && (
            <div className="bp-md:ml-30">
              <Separator
                heading={t(
                  "screens-ProofOfIdentity-relatedDocumentsLabel",
                  "Related Documents for {{selectedIdentityDocument}}",
                  {
                    selectedIdentityDocument: selectedIdentityDocument?.name,
                  }
                )}
                containerClass="mt-40 mb-8"
              />
              <Document
                containerClass="mb-0 js-doc-wrap"
                documentUpload={identityDocumentUploads[1]}
                status={getDocumentUploadStatusFlag(identityDocumentUploads[1])}
              />

              <Document
                containerClass="mt-30 mb-0 js-doc-wrap"
                documentUpload={identityDocumentUploads[2]}
                status={getDocumentUploadStatusFlag(identityDocumentUploads[2])}
              />
            </div>
          )}
          {(nameChangeDocumentUploads?.length > 0 || noDocsOption === 1) && (
            <NameChangeUploads
              shouldShowSelect={shouldShowSelect}
              nameChangeCatDocData={nameChangeCatDocData}
            />
          )}
        </>
      )}
      {isPendingConfirmationStatus(identityDocumentUploads[0]?.ATD) &&
        !confirmFields && selectedIdentityDocument?.id === identityDocumentUploads[0]?.catDocId && (
          <Alert
            alertStyle={ALERT_ERROR}
            alertIcon={ICON_STOP}
            iconSize={ICON_SMALL}
            ariaLive="off"
            containerClass="mt-24 mb-24 w-100"
            hasBorder={false}
          >
            <h4 className="text--primary text--blue-dark-2 text--xmd text--700">
              {t(
                "screens-Dashboard-unconfirmedMessage",
                "We are unable to confirm the details on your document. Please try again."
              )}
            </h4>
          </Alert>
        )}
    </StatusContainer>
  );
}
