export const isApplicationNch = (applicationType) => {
    return applicationType === "nch";
}

export const nchStatusSubtitles = {
    "processing": "Your documents and payment have been accepted; however, there was an issue processing your application. An email was sent to {{email}} with next steps.",
    "processed": "Your application has been processed successfully. You should receive your updated driver's license/identification card in 2-3 weeks.",
    "error": "Your documents and payment have been accepted by our online verification system, however we have encountered an issue with processing your application. Please visit a field office to complete your application.",
    "paymentNeeded": "Your documents have been approved. Complete your payment to finalize your application."
}

export const NCH_STATUS_ERROR = "error";
export const NCH_STATUS_PROCESSING = "processing";
export const NCH_STATUS_PROCESSED = "processed";
export const NCH_STATUS_PENDING = "paymentNeeded";

export const categoryIds = {
    "NCH_IDENTITY": 9,
    "NCH_SIGNATURE": 10,
    "NCH_NAME_CHANGE": 11,
    "NCH_NAME_TRACING": 12,
}

export const nchCategoryNameMap = {
    [categoryIds.NCH_IDENTITY]: "nch-screens-category9-legend",
    [categoryIds.NCH_SIGNATURE]: "nch-screens-category10-legend",
    [categoryIds.NCH_NAME_CHANGE]: "nch-screens-category11-legend",
    [categoryIds.NCH_NAME_TRACING]: "nch-screens-category12-legend"
};