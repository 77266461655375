import { useMemo } from "react";
import sortDocuments from "./sortDocuments";

function sanitizeDescription(description) {
  // Remove all HTML tags from the description.
  let sanitizedDescription = description.replace(/<[^>]*>/g, "");

  // Convert all special characters.
  sanitizedDescription = sanitizedDescription
    .replace(/&amp;/g, "&")
    .replace(/&ldquo;/g, '"')
    .replace(/&rdquo;/g, '"');

  return sanitizedDescription.trim();
}

function getLabel({ name }) {
  if (
    name !== "Unexpired Foreign Passport" &&
    name.indexOf("EAD") === -1 &&
    name !== "Permanent Resident Card"
  ) {
    return name;
  }

  return name;
}

export default function useSortedDocuments({ documents }) {
  // Sort documents by document_tiles_order.
  const sortedDocuments = sortDocuments(documents);

  const documentOptions = (sortedDocuments || []).map(
    ({ name, documentId, preferred_documents: preferred, description }) => ({
      label: getLabel({ name }),
      value: documentId,
      hint: sanitizeDescription(description),
      preferred,
    })
  );

const documentsList =  [...documentOptions]
  return useMemo(
    () => documentsList,
    [documentOptions]
  );
}
