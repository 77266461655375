import { APPLICATION_TYPE } from "@ca-dmv-radv/data";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    debug: false, // Set to true to log missing translations in the console.
    backend: {
      loadPath: () => {
        const applicationType = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
        const i18nPath = applicationType === APPLICATION_TYPE.DV 
          ? "/i18n/ui/{{lng}}"
          : `/${applicationType}/i18n/ui/{{lng}}`;
        return `${process.env.REACT_APP_API_URL}${i18nPath}`;
      },
      parse(data) {
        return JSON.parse(data).data;
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: false,
    },
  })
  .then(() => {
    // Finished loading
  });
