import React, { useState, useEffect } from "react";
import {
  InputGroup,
  DatePicker,
  InputText,
  PATTERN_US_PASSPORT,
  PATTERN_US_PASSPORT_CARD,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useFileUpload } from "@ca-dmv-radv/data";
import useVerificationFieldsValidation from "./useVerificationFieldsValidation";
import { isMobile } from "react-device-detect";
import { isPassPort, isPassportCard } from '../helpers/constants';

export default function ExpirationDateForm({ isAbbyyFlow }) {
  const { setShowInputError, expirationDateFormData, setExpirationDateFormData } = useFileUpload();
  const { t } = useTranslation();
  const { validateGeneralExpirationDate } = useVerificationFieldsValidation();
  const [month, setMonth] = useState(expirationDateFormData?.Expiration_Date?.split("-")?.[1] || '');
  const [day, setDay] = useState(expirationDateFormData?.Expiration_Date?.split("-")?.[2] || '');
  const [year, setYear] = useState(expirationDateFormData?.Expiration_Date?.split("-")?.[0]) || '';
  const [expDateErrors, setExpDateErrors] = useState({});

  const setData=({key, value}) => setExpirationDateFormData({
    ...expirationDateFormData,
    [key]: value,
  });

  useEffect(() => {
    setData({ key: "Expiration_Date", value: `${year}-${month}-${day}`})
    if (isAbbyyFlow) {
      setExpDateErrors({});
      const isValid = validateGeneralExpirationDate(`${year}-${month}-${day}`);
      if (!isValid && year) {
        setExpDateErrors({
          year: t(
            "Expiration-form-Expiration-invalid-date",
            "Expiration date must be at least 2 days from today and not more than 10 years from today."
          ),
        });
      }
    }
  }, [month, day, year]);

  useEffect(() => {
    if(expDateErrors.year){
      setShowInputError(true);
    } else {
      setShowInputError(false);
    }
  }, [expDateErrors]);

  let formTitle;
  let expLabel;

  formTitle = t(
    "screens-Dashboard-ExpirationDate-Legend",
    "Please provide your Expiration Date."
  );
  expLabel = t(
    "screens-Dashboard-ExpirationDate-Label",
    "Expiration Date"
  );
  
  return (
    <InputGroup
      containerClass="mt-48"
      inputGroupClass="border p-24"
      legend={formTitle}
      legendClass="text--blue-dark-2 text--md text--700 text--lh-1 w--100"
      title={t(
        "screens-Dashboard-Passport-Title",
        "This information will be used for document verification"
      )}
      titleClass="text--blue-dark-2 mt-8 mb-16"
    >
      <DatePicker
        containerClass="w--100 mb-0 pb-0"
        legend={expLabel}
        legendClass="text--uppercase text--xxxsm"
        monthAsSelect={false}
        required
        monthLabel={t("screens-Dashboard-Passport-MonthLabel", "Month")}
        dayLabel={t("screens-Dashboard-Passport-DayLabel", "Day")}
        yearLabel={t("screens-Dashboard-Passport-YearLabel", "Year")}
        month={month}
        day={day}
        year={year}
        onMonthChange={(newMonth) => {
          setMonth(newMonth);
        }}
        onDayChange={(newDay) => {
          setDay(newDay);
        }}
        onYearChange={(newYear) => {
          setYear(newYear);
        }}
        showErrors={expDateErrors?.year}
        errorText={expDateErrors?.year}
        errors={expDateErrors}
      />
    </InputGroup>
  );
}
