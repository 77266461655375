import { useCatalogs, useFileUpload, useApplication } from "@ca-dmv-radv/data";
import React, { useEffect } from "react";
import BirthCerificateForm from "./BirthCertificateForm";
import PassportForm from "./PassportForm";
import { isAbbyyFlow, isBirthCertificate, isPassPort, isPassportCard } from '../helpers/constants';
import ExpirationDateForm from "./ExpirationDateForm";

export default function VerificationFields({ documentUpload }) {
  const { states, setShouldFetchStates, counties, setShouldFetchCounties } =
    useCatalogs();
  const {
    birthCertificateFormData,
    setBirthCertificateFormData,
  } = useFileUpload();
  useEffect(() => {
    if (isBirthCertificate(documentUpload)) {
      setShouldFetchStates(true);
      setShouldFetchCounties(true);
    }
  }, [documentUpload]);


  const handleBCFormDataChange = ({ key, value }) => {
    setBirthCertificateFormData({
      ...birthCertificateFormData,
      [key]: value,
    });
  };
  return (
    <>
      {isBirthCertificate(documentUpload) && (
        <BirthCerificateForm
          states={states}
          counties={counties}
          changeHandler={handleBCFormDataChange}
          data={birthCertificateFormData}
        />
      )}

      {(isPassPort(documentUpload) || isPassportCard(documentUpload)) && (
        <PassportForm
          documentUpload={documentUpload}
          isAbbyyFlow={isAbbyyFlow(documentUpload.ATD)}
        />
      )}

      {documentUpload.requiredExpirationDate && (
        <ExpirationDateForm isAbbyyFlow={isAbbyyFlow(documentUpload.ATD)} />
      )}
    </>
  );
}
