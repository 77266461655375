import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Alert, ALERT_SUCCESS } from "@ca-dmv/core";

export default function ReadyToSubmit({skipDmv}) {
  const { t } = useTranslation();

  const readyToSubmitSubheader = (skipDmv) => {
    if (skipDmv === undefined) {
      return t(
        "screens-Dashboard-readyToSubmit-explanation-1",
        "A visit to the DMV is the final step in your REAL ID application."
      )
    } else {
        if (skipDmv) {
          return t(
            "nch-screens-Dashboard-readyToSubmit-explanation-1",
            "Submit your documents and make payment to finalize your application."
          );
        } else {
          return t(
            "nch-screens-Dashboard-readyToSubmit-explanation-2",
            "A visit to the DMV is the final step in your application."
          );
        }
      }
    }

  return (
    <Alert
      alertStyle={ALERT_SUCCESS}
      containerClass="mt-48 mb-0 max-width--600"
      hasBorder={false}
      role="alert"
    >
      <h4 className="text--primary text--700 mb-8">
        {t(
          "screens-Dashboard-readyToSubmit",
          "Your documents have been verified and are ready to submit."
        )}
      </h4>
      <p className="text--blue-dark-2">
        {readyToSubmitSubheader(skipDmv)}
      </p>
    </Alert>
  );
}
