import React from "react";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import {
  Icon,
  ICON_WOMAN_AT_COMPUTER,
  Button,
  BUTTON_STYLE_LINK,
  ButtonWrapper,
} from "@ca-dmv/core";
import { RadvPageWrapper } from "@ca-dmv-radv/components";
import { useApplication } from "@ca-dmv-radv/data";
import { isApplicationNch } from "../constants";

export default function SaveAndExit({ title, isLogout }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const { application, applicationType } = useApplication();
  const email = application?.email;
  const imageLink = process.env.REACT_APP_IMAGE_PATHS + "/img/icons/Online_computer_person.svg";
  const isNch = isApplicationNch(applicationType);

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center w--100 bp-md:max-width--700 ml-auto mr-auto">
        <div className="mb-40">
          <img
            src={imageLink}
            alt="Woman at computer."
            width="300"
            height="500"
          />
        </div>
        <h2 className="h3 text--xmd bp-sm:text--med bp-md:text--xlg text--700   text--center mb-24 bp-md:mb-48">
          {t(
            "screens-SaveAndExit-heading",
            "Your Progress is Saved!"
          )}
        </h2>
        {email && !isNch && (
          <h3 className="text--p text--md mb-20 text--center text--black max-width--600">
            <Trans
              i18nKey="screens-SaveAndExit-messageEmail"
              defaults="<span>An email was sent to {{email}}</span>."
              values={{
                email,
              }}
              components={{ span: <span className="text--700" /> }}
            />
          </h3>
        )}
        {!isNch && <p className="text--xmd mb-24 text--center text--black max-width--800">
          {isLogout ? t(
            "screens-LogOut-messageCompleteApplication",
            "You can continue your application at any time from your email or you can log in to your MyDMV account."
           ) : t(
            "screens-SaveAndExit-messageCompleteApplication",
            "You can return to your application or continue at any time from your email."
          )}
        </p>}
        {isNch && <p className="text--xmd mb-24 text--center text--black max-width--800">
          {t(
            "screens-SaveAndExit-messageContinueYourApplication",
            "You can log in to your MyDMV account to continue your application at any time."
          )}
        </p>}
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_LINK}
            buttonClass="mb-0 bp-md:mr-24"
            onClick={() => {
              window.location = process.env.REACT_APP_PORTAL_URL;
            }}
          />
          <Button
            label={isLogout || isNch
              ? t(
                "screens-logout-buttonLabel-login",
                "Log in to your MyDMV account"
              ): t(
                "screens-SaveAndExit-buttonLabel-ReturnToApplication",
                "Return to Application"
              )}
            buttonClass="mb-40 bp-md:mb-0 bp-md:mr-24"
            onClick={() => {
              window.location = process.env.REACT_APP_BACK_TO_DMV_LOGIN;
            }}
          />
        </ButtonWrapper>
      </div>
    </RadvPageWrapper>
  );
}
