import React, { useState } from "react";
import { Modal } from "@ca-dmv/modal";
import {
  Button,
  BUTTON_STYLE_SECONDARY,
  BUTTON_ICON_LEFT,
  ICON_ADD_CIRCLE,
  ICON_TRASH,
  Alert,
  ALERT_ERROR,
} from "@ca-dmv/core";
import { useFileUpload } from "@ca-dmv-radv/data";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import UploadPreviews from "./UploadPreviews";
import VerificationFields from "./VerificationFields";
import useVerificationFieldsValidation from "./useVerificationFieldsValidation";
import Dropzone from "./Dropzone";
import UploadInstructions from "./UploadInstructions";
import { isBirthCertificate, isPassPort, isPassportCard, isSignature, isDriversLicense, isIdentificationCard } from "../helpers/constants";

export default function Upload({
  onClose,
  modalTitle,
  documentUpload,
  uploadDocumentFiles,
}) {
  const { t } = useTranslation();

  const {
    files,
    setFiles,
    addingAdditionalPage,
    setAddingAdditionalPage,
    uploadError,
    uploading,
    showInputError
  } = useFileUpload();

  const [saving, setSaving] = useState(false);
  const [fileError, setFileError] = useState(null);
  const { validBCForm, isValidPassportForm, isValidExpirationDateForm } =
    useVerificationFieldsValidation();

  const documentUploadFiles = files?.[documentUpload.id] || [];
  const requiredNumberPages = documentUpload.requiredPages;

  const canAdd = requiredNumberPages
    ? documentUploadFiles.length < requiredNumberPages.length
    : documentUpload.multiplePages === true;

  const isNextDisabled =
    saving ||
    !documentUploadFiles?.length ||
    (requiredNumberPages &&
      requiredNumberPages.length !== documentUploadFiles.length) ||
    (isBirthCertificate(documentUpload) && validBCForm) ||
    ((isPassPort(documentUpload) || isPassportCard(documentUpload)) &&
      !isValidPassportForm(documentUpload.catDocId)) || 
    (documentUpload.requiredExpirationDate && 
      !isValidExpirationDateForm) || showInputError;

  return (
    <Modal
      onClose={saving ? null : onClose}
      modalTitle={modalTitle}
      contentContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30 width--100"
    >
      {uploadError && <div>{uploadError.message}</div>}
      {isSignature(documentUpload) &&
        <div>
          <p className="text--xmd text--lh-sm text--blue-dark-2">
            <Trans
              i18nKey="nch-screens-category10-instructions"
              defaults="<p1>Sign a blank, unlined white piece of paper using blue or black ink.</p1><p2> Make sure the signature is clear.</p2>"
              components={{
                p1: <span className="show mb-16" />,
                p2: <span className="show mb-0" />,
              }}
            />
          </p>
          <h4 className="text--h5 pt-10">
            <b>
                <Trans
                    i18nKey="nch-screens-category10-upload"
                    defaults="Upload Your Signature"
                />
            </b>
          </h4>
        </div>
      }
      {(isDriversLicense(documentUpload) || (isIdentificationCard(documentUpload))) && (
        <p className="text--blue-dark-2 mb-16">
          {t(
            "screens-nch-category9-DL-ID-front", 
            "{{documentName}} requires you to only upload the front of the card.",
            { documentName: documentUpload.name }
          )}
        </p>
      )}
      <UploadPreviews
        documentUploadFiles={documentUploadFiles}
        documentUpload={documentUpload}
      />
      {fileError && (
        <Alert
          alertStyle={ALERT_ERROR}
          ariaLive="off"
          containerClass="mt-24"
          content={fileError}
          contentClass="text--xsm"
        />
      )}
      {requiredNumberPages?.length === 2 && (
          <p className="text--blue-dark-2 mb-16">
            {t(
              "screens-Dashboard-Modal-FrontAndBack",
              "{{documentName}} requires both the front and back of this card",
              { documentName: documentUpload.name }
            )}
          </p>
        )}

      {(documentUploadFiles.length === 0 || addingAdditionalPage) && (
        <Dropzone
          documentUpload={documentUpload}
          setAddingAdditionalPage={setAddingAdditionalPage}
          setFileError={setFileError}
        />
      )}

      {documentUploadFiles.length > 0 && !addingAdditionalPage && (
        <div className="mt-24">
          <p className="text--sm text--blue-dark-2 text--700 w--100 mb-16">
            {t(
              "screens-ProofOfIdentity-Modal-Upload-moreImageOptionsLabel",
              "More Image Options"
            )}
          </p>
          <div className="flex flex--align-center flex-wrap ">
            {canAdd && (
              <>
                <Button
                  icon={ICON_ADD_CIRCLE}
                  iconPosition={BUTTON_ICON_LEFT}
                  btnStyle={BUTTON_STYLE_SECONDARY}
                  buttonClass="border--md border-radius--md text--700 flex--align-center"
                  isLoading={uploading}
                  onClick={() => {
                    setAddingAdditionalPage(true);
                  }}
                  label={t(
                    "screens-ProofOfIdentity-Modal-Upload-buttonAddLabel",
                    "Add"
                  )}
                />
                <span className="text--sm text--blue-dark-2 text--700 ml-16 mr-16">
                  {t(
                    "screens-ProofOfIdentity-Modal-Upload-buttonOrLabel",
                    "or"
                  )}
                </span>
              </>
            )}

            <Button
              icon={ICON_TRASH}
              iconPosition={BUTTON_ICON_LEFT}
              btnStyle={BUTTON_STYLE_SECONDARY}
              buttonClass="border--md border-radius--md text--700 flex--align-center border--blue-dark-2 text--blue-dark-2"
              isLoading={uploading}
              onClick={() => {
                setFiles((currentFiles) => {
                  const nextFiles = { ...currentFiles };

                  // Remove last item.
                  nextFiles[documentUpload.id] = nextFiles[
                    documentUpload.id
                  ].slice(0, nextFiles[documentUpload.id].length - 1);

                  return nextFiles;
                });
              }}
              label={t(
                "screens-ProofOfIdentity-Modal-Upload-buttonRemoveLabel",
                "Remove"
              )}
            />
          </div>
        </div>
      )}

      <UploadInstructions document={documentUpload} />

      <VerificationFields documentUpload={documentUpload} />

      <div className="flex flex--align-center flex--justify-between mt-40">
        <Button
          label={t(
            "screens-ProofOfIdentity-Modal-Upload-buttonCancelLabel",
            "Cancel"
          )}
          btnStyle={BUTTON_STYLE_SECONDARY}
          disabled={saving}
          onClick={onClose}
        />
        <Button
          label={t(
            "screens-ProofOfIdentity-Modal-Upload-buttonNextLabel",
            "Next"
          )}
          disabled={isNextDisabled}
          onClick={async () => {
            setSaving(true);
            await uploadDocumentFiles(documentUpload);
            onClose();
          }}
          isLoading={saving}
        />
      </div>
    </Modal>
  );
}
