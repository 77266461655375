import { APPLICATION_TYPE } from "@ca-dmv-radv/data";
import routes, { mdlRoutes, nchRoutes, odpRoutes, vdlRoutes } from "./routes";

export default function isAuthenticatedRoute() {
  let checkMdl;
  if (window.location.pathname.includes("mdl")) {
    checkMdl = `^/mdl(${mdlRoutes.map(({ path }) => path).join("|")})/?$`;
  } else if (window.location.pathname.includes("odp")) {
    checkMdl = `^/odp(${odpRoutes.map(({ path }) => path).join("|")})/?$`;
  } else if (window.location.pathname.includes("vdl")) {
    checkMdl = `^/vdl(${vdlRoutes.map(({ path }) => path).join("|")})/?$`;
  } else if (window.location.pathname.includes("nch")) {
    checkMdl = `^/nch(${nchRoutes.map(({ path }) => path).join("|")})/?$`;
  } else {
    checkMdl = `^/dv(${routes.map(({ path }) => path).join("|")})?/?$`;
  }
  const authenticatedRoutesRegex = new RegExp(checkMdl);

  return window.location.pathname.match(authenticatedRoutesRegex);
}

export const isAuthenticated = (pathname, applicationType) => {
  let determinedRoutes;
  switch (applicationType) {
    case APPLICATION_TYPE.MDL:
      determinedRoutes = mdlRoutes;
      break;
    case APPLICATION_TYPE.ODP:
      determinedRoutes = odpRoutes;
      break;
    case APPLICATION_TYPE.VDL:
      determinedRoutes = vdlRoutes;
      break;
    case APPLICATION_TYPE.NCH:
      determinedRoutes = nchRoutes;
      break;
    case APPLICATION_TYPE.DV:
    default:
      determinedRoutes = routes;
  }
  return Boolean(
    determinedRoutes.find(({ path }) => path === pathname && path !== "/saved")
  );
};
