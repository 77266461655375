import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { APPLICATION_TYPE, mobileSessionDv, mobileSessionDynamic } from '@ca-dmv-radv/data';
import { useTranslation } from '@ca-dmv-radv/translation';
import { getParamUrl } from '@ca-dmv-radv/utilities';
import { Button, BUTTON_STYLE_LINK, ButtonWrapper, Icon, ICON_ID } from '@ca-dmv/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function MobileSessionDv({ title }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const urlPath = window.location.pathname 
    if (urlPath.includes("expired")) {
      setErrorMessage(t("screens-MDL-expired-message", "The link you are using has expired."));
    } else {
      (async () => {
        let success;
        let message;
        let data;
  
        try {
          ({ success, message, data } = await mobileSessionDynamic(params.token, APPLICATION_TYPE.DV));
  
          if (success === true) {
            if (data !== null && data.event === "sms_takeSelfiePhotoSend") {
              window.location.href = getParamUrl(
                `/dv/presence-verification`,
                params
              );
            } else {
              window.location.href = getParamUrl("/dv", params);
            }
          } else {
            setErrorMessage(message);
          }
        } catch (error) {
          if (error.response) {
            setErrorMessage(error.response?.data?.message);
          }
  
          if (error.name === "MaintenanceEnabled") {
            window.location.href = ("/dv/under-maintenance");
          }
        }
      })();
    } 
  }, []);

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
      <div className="flex flex--col flex--align-center">
        <div className="min-width--200 mb-40 mt-20">
          <Icon icon={ICON_ID} alt={t("screens-clock-img-alt", "clock")} />
        </div>
        {!errorMessage && (
          <h2 className="h3 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
            {t(
              "screens-redirection-heading",
              "Hang on, you are being redirected to the Dashboard"
            )}
          </h2>
        )}
        {errorMessage && (
          <>
            <h2 className="h3 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
              {t("screens-error-heading", "Oops, something went wrong")}
            </h2>
            <p className="text--sm mb-0 text--center max-width--400">
              {errorMessage}
            </p>
          </>
        )}
        <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_LINK}
            buttonClass="mb-0 bp-md:mr-40"
            onClick={() => {
              window.location = process.env.REACT_APP_PORTAL_URL;
            }}
          />
          <Button
            buttonClass="mb-40 bp-md:mb-0"
            label={t(
              "screens-Timeout-buttonLabel-ReturnToApplication",
              "Return to Application"
            )}
            onClick={() => {
              window.location = process.env.REACT_APP_EDL_URL;
            }}
          />
        </ButtonWrapper>
      </div>
    </RadvPageWrapper>
  );
}
