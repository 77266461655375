import React, { useCallback, useEffect, useState } from "react";
import {
  YesNoRadioGroup,
  Button,
  ButtonWrapper,
  useFocusedKey,
} from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import {
  RadvPageWrapper,
  NameConfirmationCard,
  CARD_RID_APPLICATION_NAME,
  ButtonSaveAndExit,
  ButtonBack,
} from "@ca-dmv-radv/components";
import { useNameChanges, useIdentityDocument } from "@ca-dmv-radv/data";
import { makeId } from "@ca-dmv-radv/utilities";
import useNameChangeErrors from "./useNameChangeErrors";
import FormFields from "./FormFields";
import useNameChangeNavigation from "./useNameChangeNavigation";
import NameCards from "./NameCards";
import SelectDocument from "./SelectDocument";
import useFocusableElement from "./useFocusableElements";
import sortDocuments from "@ca-dmv-radv/utilities/src/sortDocuments";

export default function IdentityNameChanges({ title }) {
  const {
    nameChanges,
    nameOnDocument,
    removeNameChange,
    addNameChangeDocument: saveAddedNameChange,
    nameChangeDocuments,
    setCatDocId: globalSetCatDocId,
    setLastName: globalSetLastName
  } = useNameChanges();
  const { selectedIdentityDocument, showIdentityNameOptions } = useIdentityDocument();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [catDocId, setCatDocId] = useState(null);
  const [needAnotherNameChange, setNeedAnotherNameChange] = useState(
    nameChanges[nameChanges.length - 1]?.catDocId === null ? true : null
  );
  const [showErrors, setShowErrors] = useState(false);

  const { focusedElementKey } = useFocusedKey();
  const errors = useNameChangeErrors({
    needAnotherNameChange,
    firstName,
    lastName,
    catDocId,
    nameChanges,
  });
  const hasErrors = Boolean(Object.keys(errors).length);
  const nameChangeRef = useFocusableElement();
  const sortedNameChangeDocuments = sortDocuments(nameChangeDocuments);
  
  /**
   * Check if the last items in the name change are defined and not null
   * @returns True if the last item in the name change array is defined with
   *          an object value
   */
  function isLastNameChangeDefined() {
    return nameChanges[nameChanges.length - 1] !== undefined &&
      nameChanges[nameChanges.length - 1] !== null
  }

  /**
   * Functional check that meets the requirements when the primary identifying document
   * type is being modified. Under the conditions, the existing last name as set by the 
   * applicant and the category document id will be set null (reset to change to a new
   * document type).
   * @returns True if the lastname is the same as the applicant and the primary category
   *          document ID is reset to null to be set with a new document type.
   */
  function isLastNameChangeBeingModified() {
    return nameChanges[nameChanges.length - 1]?.catDocId === null &&
      nameChanges[nameChanges.length - 1].lastName === lastName
  }

  /**
   * Handle click on "Add New Name" button.
   */
  const requestAddNameChange = useCallback(async () => {

    setShowErrors(hasErrors);
    if (hasErrors) {
      return;
    }
    
    saveAddedNameChange({
      firstName,
      middleName,
      lastName,
      suffix,
      done: false,
      id: null,
      key: makeId(),
      catDocId,
      // When the user lands on this screen for the first time, the first thing we need to do
      // is get the catDocId for the document certifying the name on their application. This
      // name change is always the last item in the nameChanges array, so if catDocId is null,
      // we know we need to set that catDocId rather than adding a whole new name change.
      // When the user lands on this screen and if it is changing an existing certifying identity
      // document, the lastname will not be specified as the user is uanble to input the lastname
      // but the lastname tieing to the associated name will be already defined in the array. The 
      // document cateogry ID will also be null as it is changing the type of document being 
      // submitted.
      isApplicationName:
        (nameChanges.length === 2 &&
        nameChanges[nameChanges.length - 1]?.catDocId === null) ||
        (nameChanges.length > 2 &&
          isLastNameChangeDefined() && isLastNameChangeBeingModified()),
    });
    setNeedAnotherNameChange(null);
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setSuffix("");
    setCatDocId(null);

    window.scrollTo(0, 0);
  }, [
    hasErrors,
    nameChanges,
    saveAddedNameChange,
    firstName,
    middleName,
    lastName,
    suffix,
    catDocId,
  ]);

  const { navigating, requestNavigation } = useNameChangeNavigation({
    setShowErrors,
    hasErrors,
  });

  // Reset needAnotherNameChange to true when the last nameChange's catDocId becomes null.
  useEffect(() => {
    if (nameChanges[nameChanges.length - 1]?.catDocId === null) {
      setNeedAnotherNameChange(true);
    }
  }, [nameChanges]);

  return (
    <RadvPageWrapper
      pageHeading={t("screens-ProofOfIdentity-legend", "Proof of Identity")}
      formProps={{
        isForm: true,
        showErrors,
        errors,
      }}
      pageTitle={title}
    >
    {!showIdentityNameOptions && 
        <div>
          <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
            {t(
              "screens-returnToDashboard-backButton-warning",
              "Please avoid using the browser navigation buttons to make changes to your application."
            )}
          </p>
          <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t("screens-returnToDashboard-button-text", "Return to Dashboard")}
            buttonClass="mb-24 bp-md:mb-0"
            onClick={requestNavigation}
            isLoading={navigating}
          />
        </ButtonWrapper>
        </div>
    }
    {showIdentityNameOptions && <div>
      <h3 className="text--h5 text--700 mb-8 bp-md:mb-16">
        {t("screens-confirm-name-subHeading", "Name Confirmation")}
      </h3>
      <p className="text--blue-dark-2 mb-40 bp-md:mb-48 max-width--800">
        {t(
          "screens-confirm-name-subHeading-description",
          "Federal regulations require DMV to issue REAL ID driver’s licenses or ID cards in accordance with the document used to establish your identity. If the name on your application does not match the name on your identity document, you will need to provide certification of the different name."
        )}
      </p>
      <div className="flex flex--col bp-md:flex--row mb-40 bp-md:mb-48 w--100 max-width--600 mr-0">
        <NameConfirmationCard
          cardType={CARD_RID_APPLICATION_NAME}
          name={nameOnDocument}
        />
        <NameCards
          nameChanges={nameChanges}
          removeNameChange={removeNameChange}
          certifyingDocuments={nameChangeDocuments}
          setCatDocId={globalSetCatDocId}
          selectedIdentityDocument={selectedIdentityDocument}
          setLastName={setLastName}
        />
      </div>
      <div ref={nameChangeRef}>
        {!nameChanges[nameChanges.length - 1]?.catDocId && (
          <SelectDocument
            legend={t(
              "screens-confirm-name-applicationNameCertifyingApplication",
              "Which document certifies the name on your application?"
            )}
            documentList={sortedNameChangeDocuments}
            onChange={(nextValue) => {
              setCatDocId(Number(nextValue));
            }}
            error={errors && showErrors && errors.certifyingDoc}
            documentId={catDocId}
            name="certifyingDoc"
            selectLabel={t(
              "screens-confirm-namme-selectDocument",
              "Select one of the documents below"
            )}
            required
            idField="documentId"
          />
        )}

        {nameChanges[nameChanges.length - 1]?.catDocId && (
          <>
            <YesNoRadioGroup
              containerClass="mb-0 w--100 max-width--600"
              name="addAnotherNameChange"
              legend={t(
                "screens-confirm-name-doYouNeedAnotherNameChangeLegend",
                "Have you had other legal names?"
              )}
              subLegend={t(
                "screens-confirm-name-doYouNeedAnotherNameChangeDescription",
                "You need to provide a document to certify every time your name has changed."
              )}
              yesLabel={t("shared.yesLabel", "Yes")}
              noLabel={t("shared.noLabel", "No")}
              onChange={setNeedAnotherNameChange}
              value={needAnotherNameChange}
              focusedElementKey={focusedElementKey}
              showErrors={showErrors}
              errorKey="needAnotherNameChange"
              translatedErrors={errors}
              yesValue
              noValue={false}
            />
            {needAnotherNameChange && (
              <FormFields
                firstName={firstName}
                setFirstName={setFirstName}
                middleName={middleName}
                setMiddleName={setMiddleName}
                lastName={lastName}
                setLastName={setLastName}
                suffix={suffix}
                setSuffix={setSuffix}
                catDocId={catDocId}
                setCatDocId={setCatDocId}
                nameChangeDocuments={sortedNameChangeDocuments}
                focusedElementKey={focusedElementKey}
                showErrors={showErrors}
                errors={errors}
              />
            )}
          </>
        )}
      </div>
      <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
        <ButtonBack buttonClass="bp-md:mr-24" />
        <ButtonSaveAndExit buttonClass="bp-md:mr-24 mb-24 bp-md:mb-0" />
        {needAnotherNameChange ? (
          <Button
            label={t(
              "screens-confirm-name-addNameChangeDocument",
              "Add Certifying Document"
            )}
            buttonClass="mb-24 bp-md:mb-0"
            buttonPurpose={t(
              "screens-confirm-name-addNameChangeDocument",
              "Add Certifying Document"
            )}
            onClick={requestAddNameChange}
          />
        ) : (
          <Button
            label={t("shared.nextLabel", "Next")}
            buttonClass="mb-24 bp-md:mb-0"
            onClick={requestNavigation}
            isLoading={navigating}
          />
        )}
      </ButtonWrapper>
      </div>}
    </RadvPageWrapper>
  );
}