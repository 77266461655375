import { useMemo, useState, useCallback } from "react";
import { useMounted } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import { APPLICATION_TYPE, DOCUMENT_CATEGORY_NAME_CHANGE, DOCUMENT_CATEGORY_NCH_NAME_TRACING, FETCH_STATUS_IDLE, FETCH_STATUS_SENDING } from "../constants";
import {
  updateNameChanges,
  postNameChangesDocument,
  getNameChanges,
} from "../api";
import { useApplication } from "../application-context-provider";
import { useThrowErrorSavingData } from "../error-context-provider";
import { parseReceivedNameChanges } from "./parseReceivedNameChanges";

/**
 * Adapted from previous version of app.
 */
export function prepareToPostNameChanges({ nameChanges }) {
  const tmp = [...nameChanges];

  if (nameChanges.length > 1) {
    // Remove first item: name in document (already in appData)
    tmp.splice(0, 1);

    for (let i = 0; i < tmp.length; i += 1) {
      tmp[i].firstName = tmp[i].firstName.replace(/\s\s+/g, " ").trim();
      tmp[i].middleName = tmp[i].middleName.replace(/\s\s+/g, " ").trim();
      tmp[i].lastName = tmp[i].lastName.replace(/\s\s+/g, " ").trim();
      tmp[i].suffix = tmp[i].suffix.replace(/\s\s+/g, " ").trim();
      tmp[i].order = i + 1;
    }
  }

  return tmp;
}

// The nameChangesDocuments must be saved one at a time. If they are saved simultaneously,
// such as with Promise.all, the identity document status is not saved properly and the
// section will be marked as incomplete.
const saveNameChangesDocuments = async (nameChangesToSave, nameChanges, applicationType, documentType, categoryId) => {
  if (!nameChangesToSave?.length) {
    return { success: true };
  }

  const [nameChangeToSave, ...nextNameChangesToSave] = nameChangesToSave;

  const nameChangesId = nameChanges.find(
    ({ order }) => order === nameChangeToSave.order
  )?.id;

  if (nameChangesId && nameChangeToSave.catDocId) {
    await postNameChangesDocument({
      nameChangesId,
      catDocId: nameChangeToSave.catDocId,
      categoryId,
      documentType,
    }, applicationType);
  }

  return saveNameChangesDocuments(nextNameChangesToSave, nameChanges, applicationType, documentType, categoryId);
};

/**
 * Saves name change data.
 * @param {Object} props
 */
export default function usePostNameChanges({
  hasNameChangeDocs,
  nameChanges,
  noDocsOption,
  setNameChanges,
  deletedNames,
  documentType,
  categoryId,
}) {
  const { application, applicationType } = useApplication();

  const [postNameChangeStatus, setPostNameChangeStatus] =
    useState(FETCH_STATUS_IDLE);

  const mounted = useMounted();
  const throwErrorSavingData = useThrowErrorSavingData();
  const { t } = useTranslation();

  const postNameChanges = useCallback(async () => {
    setPostNameChangeStatus(FETCH_STATUS_SENDING);

    let success;
    try {
      ({ success } = await updateNameChanges({
        hasNameChangeDocs: hasNameChangeDocs || false,
        names: hasNameChangeDocs
          ? prepareToPostNameChanges({
              nameChanges,
            })
          : [],
        deletedNames,
        noDocsOption: hasNameChangeDocs ? null : noDocsOption || null,
        documentType,
        categoryId
      }, applicationType));

      if (!success) {
        throw new Error();
      }

      let nextNameChanges = await getNameChanges(applicationType);
      nextNameChanges = parseReceivedNameChanges(
        nextNameChanges.data,
        application,
        documentType
      );

      ({ success } = await saveNameChangesDocuments(
        nameChanges.slice(1),
        nextNameChanges,
        applicationType,
        documentType,
        categoryId
      ));

      if (!success) {
        throw new Error();
      }

      // Slightly hacky way to prevent UI changes before the screen is ready to navigate. This
      // callback is meant to run in the UI onclick handler after subsequent fetches and before
      // actual navigation.
      return async ({ application: latestApplication }) => {
        let nameChangeSuccess;
        let data;

        try {
          ({ data, success: nameChangeSuccess } = await getNameChanges(applicationType));

          if (!nameChangeSuccess) {
            throw new Error();
          }

          const finalNameChanges = parseReceivedNameChanges(
            data,
            latestApplication,
            documentType
          );

          if (mounted.current) {
            setNameChanges(finalNameChanges);
          }
        } catch (error) {
          throwErrorSavingData({ error });
        }

        return nameChangeSuccess;
      };
    } catch (error) {
      throwErrorSavingData({ error });

      return () => null;
    } finally {
      if (mounted.current) {
        setPostNameChangeStatus(FETCH_STATUS_IDLE);
      }
    }
  }, [
    hasNameChangeDocs,
    nameChanges,
    noDocsOption,
    setNameChanges,
    setPostNameChangeStatus,
    throwErrorSavingData,
    application,
    t,
  ]);

  return useMemo(
    () => ({
      postNameChangeStatus,
      postNameChanges,
    }),
    [postNameChangeStatus, postNameChanges]
  );
}
